import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'

import { useTranslation } from 'next-i18next'
import { getDropOffMinDate, getInitialDate } from '../utils/dateutils'


interface DateTimePickerProps {
  pickupDateTime: Date
  returnPickupTime: Date
  setPickupDateTime: (d: Date | null) => void
  setReturnDateTime: (d: Date | null) => void
}

interface DatePickerInputRef {
  input: HTMLInputElement;
}


function DateTimePicker({
    pickupDateTime,
    returnPickupTime,
    setPickupDateTime,
    setReturnDateTime,
}: DateTimePickerProps) {
    const [startDate, setStartDate] = useState<Date | null>(pickupDateTime || null)
    const [startTime, setStartTime] = useState<Date | null>(pickupDateTime || null)
    const [endDate, setEndDate] = useState<Date | null>(returnPickupTime || null)
    const [endTime, setEndTime] = useState<Date | null>(returnPickupTime || null)

    const startdateref = useRef<DatePickerInputRef>(null)
    const timepickerref = useRef<DatePickerInputRef>(null)
    const enddateref = useRef<DatePickerInputRef>(null)
    const endtimepickerref = useRef<DatePickerInputRef>(null)

    const { t, ready } = useTranslation()

    useEffect(() => {
    // console.debug('DateTimePicker useEffect[]', { pickupDateTime, returnPickupTime })
        if (pickupDateTime) {
            setStartDate(pickupDateTime)
            setStartTime(pickupDateTime)
        }

        if (returnPickupTime) {
            setEndDate(returnPickupTime)
            setEndTime(returnPickupTime)
        }
    }, [pickupDateTime, returnPickupTime])

    useEffect(() => {
    // console.debug('DateTimePicker useEffect[startDate]', { startDate })
        if (startDate) {
            setPickupDateTime(startDate)
        }
    }, [startDate, startTime])

    useEffect(() => {
    // console.debug('DateTimePicker useEffect[endDate]', { startDate })
        if (endDate) {
            setReturnDateTime(endDate)
        }
    }, [endDate, endTime])

    const handleChange = (date: Date) => {
        const isAfter = dayjs(date).isAfter(endDate)
        setStartDate(date)
        if (endDate === null || isAfter) {
            let _endDate = new Date(date)
            _endDate = new Date(_endDate.setTime(_endDate.getTime() + 7 * 86400000))
            setEndDate(_endDate)
        }
        if (date !== null && timepickerref?.current) timepickerref.current.input.click()
    }

    const handleTimeChange = (time: Date) => {
        if (startDate === null && startdateref?.current) {
            startdateref.current.input.click()
        } else if (time !== null) {
            setStartTime(time)
            const hours = time.getHours()
            const minutes = time.getMinutes()

            if (startDate) {
                const newStartDate = new Date(startDate)
                newStartDate.setHours(hours)
                newStartDate.setMinutes(minutes)
                setStartDate(newStartDate)
            }

            if ( endDate) {
                const newEndDate = new Date(endDate)
                newEndDate.setHours(hours)
                newEndDate.setMinutes(minutes)
                setEndDate(newEndDate)
                setEndTime(time)
            }
        }
    }


    const handleChangeReturn = (date: Date) => {
        const isAfter = dayjs(date).isAfter(startDate)
        if (isAfter) {
            // console.debug('DateTimePicker handleChangeReturn', { date })
            setEndDate(date)
            if (date !== null && endtimepickerref?.current) endtimepickerref.current.input.click()
        }
    }

    const handleEndTimeChange = (time: Date) => {
        if (endDate === null && enddateref?.current) {
            enddateref.current.input.click()
        } else if (time !== null) {
            setEndTime(time)
            const hours = time.getHours()
            const minutes = time.getMinutes()

            if (endDate) {
                const newEndDate = new Date(endDate)
                newEndDate.setHours(hours)
                newEndDate.setMinutes(minutes)
                setEndDate(newEndDate)
            }
        }
    }
    return (
        <>
            {ready && (
                <>
                    <div className="date-picker-container">
                        <div style={{ marginBottom: '20px' }}>
                            <label htmlFor="pickup-date">{t('Pick-up date')}</label>&nbsp;
                            <label htmlFor="pickup-time">{t('& Time')}</label>
                        </div>
                        <div className="pickupdateTime">
                            <DatePicker
                                minDate={getInitialDate()}
                                id="pickup-date"
                                ref={startdateref}
                                selected={startDate}
                                onSelect={handleChange}
                                className="date-container"
                                shouldCloseOnSelect={true}
                                placeholderText={t('Select Pickup Date')}
                                dateFormat="dd MMM,yyyy"
                            />
                            <DatePicker
                                id="pickup-time"
                                ref={timepickerref}
                                selected={startTime}
                                timeIntervals={30}
                                onSelect={handleTimeChange}
                                onChange={handleTimeChange}
                                className="time-container"
                                shouldCloseOnSelect={true}
                                placeholderText={t('& Time')}
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                showDateSelect={false}
                                preventOpenOnFocus={true}
                                showTimeSelect={!!startDate}
                                showTimeSelectOnly={!!startDate}
                            />
                        </div>
                    </div>

                    <div className="date-picker-container">
                        <div>
                            <label htmlFor="dropoff-date">{t('Drop-off date')}</label>&nbsp;
                            <label htmlFor="dropoff-time">{t('& Time')}</label>
                        </div>
                        <div className="pickupdateTime">
                            <DatePicker
                                id="dropoff-date"
                                minDate={getDropOffMinDate(startDate)}
                                ref={enddateref}
                                selected={endDate}
                                className="date-container"
                                onSelect={handleChangeReturn}
                                shouldCloseOnSelect={true}
                                placeholderText={t('Select Dropoff Date')}
                                dateFormat="dd MMM, yyyy"
                            />
                            <DatePicker
                                id="dropoff-time"
                                ref={endtimepickerref}
                                selected={endTime}
                                className="time-container"
                                onSelect={handleEndTimeChange}
                                onChange={handleEndTimeChange}
                                shouldCloseOnSelect={true}
                                placeholderText={t('& Time')}
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                showDateSelect={false}
                                showTimeSelect={!!endDate}
                                showTimeSelectOnly={!!endDate}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    )
}


export default DateTimePicker
