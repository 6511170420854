import React from 'react'
import Select from 'react-select'
import { FuelTypes } from '../../types'
import { useTranslation } from 'next-i18next'

const FormFuel = ({ setFuel, fuel }) => {
    const { t } = useTranslation()

    const fuelOptions = [
        { value: 'benzine', label: t(FuelTypes.BENZINE) },
        { value: 'diesel', label: t(FuelTypes.DIESEL) },
        { value: 'LPG', label: t(FuelTypes.LPG) },
        { value: 'gas', label: t(FuelTypes.GAS) },
        { value: 'electric', label: t(FuelTypes.ELECTRIC) },
        { value: 'hybrid', label: t(FuelTypes.HYBRID) },
    ]

    const onChange = (selectedOption) => {
        if (selectedOption) {
            setFuel(selectedOption.value)
        } else {
            setFuel('')
        }
    }

    const fuelType = fuelOptions.find((option) => option.value === fuel) || null
    return (
        <div className="categories_container">
            <Select
                value={fuelType}
                onChange={onChange}
                options={fuelOptions}
                className=""
                placeholder={t('Fuel Type')}
            />
        </div>
    )
}

export default FormFuel
