import React from 'react'
import { VehicleTypes } from '../../types'
import Search from '../../containers/Search'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { useTranslation } from 'next-i18next'
import { searchBoxParametersAtom } from '../../utils/atoms'
import { useAtom } from 'jotai'

function SearchTabs() {
    const { t, ready } = useTranslation()
    const [searchBoxParameters, setSearchBoxParameters] = useAtom(searchBoxParametersAtom)

    const preSelectedVehicle = searchBoxParameters?.vCategory?.toLowerCase() || VehicleTypes.CAR
    const defIndex =
    preSelectedVehicle === VehicleTypes.MOTO ? 1 : preSelectedVehicle === VehicleTypes.ATV ? 2 : 0

    const handleTabSelect = (index) => {
        let newVCategory
        switch (index) {
        case 0:
            newVCategory = VehicleTypes.CAR
            break
        case 1:
            newVCategory = VehicleTypes.MOTO
            break
        case 2:
            newVCategory = VehicleTypes.ATV
            break
        default:
            newVCategory = VehicleTypes.CAR
        }

        setSearchBoxParameters((prev) => ({
            ...prev,
            vCategory: newVCategory,
            vType: null,
        }))
    }

    return (
        <>
            {ready && (
                <Tabs defaultIndex={defIndex} onSelect={handleTabSelect}>
                    <TabList>
                        <Tab>{t('CARS')}</Tab>
                        <Tab>{t('MOTOS')}</Tab>
                        <Tab>{t('ATVs')}</Tab>
                    </TabList>
                    <TabPanel>
                        <Search type="Car" />
                    </TabPanel>
                    <TabPanel>
                        <Search type="Moto" />
                    </TabPanel>
                    <TabPanel>
                        <Search type="ATV" />
                    </TabPanel>
                </Tabs>
            )}
        </>
    )
}

export default SearchTabs
