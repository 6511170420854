import React from 'react'
import Select from 'react-select'
import { useTranslation } from 'next-i18next'

const FormSeats = ({ setSeats, seats }) => {
    const { t } = useTranslation()

    const newSeats = []
    const minValue = 2
    const maxValue = 9

    for (let i = minValue; i <= maxValue; i++) {
        newSeats.push({ value: i, label: i.toString() })
    }

    const selectedSeat = newSeats.find((seat) => seat.value === seats) || null

    const onChange = (selectedOption) => {
        if (selectedOption) {
            setSeats(selectedOption.value)
        } else {
            setSeats(0)
        }
    }

    return (
        <div className="categories_container">
            <Select
                value={selectedSeat}
                onChange={onChange}
                options={newSeats}
                className=""
                placeholder={t('Number of Seats')}
            />
        </div>
    )
}

export default FormSeats
